var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateInputWidth = exports.measureText = exports.getFontShorthand = void 0;
var allowedVariants = ["normal", "small-caps"];
/**
 * Gets font CSS shorthand property given element.
 *
 * @param {HTMLElement} element Element to get font CSS shorthand property from
 */
function getFontShorthand(element) {
  if (!element) {
    return "";
  }
  var style = window.getComputedStyle(element);
  if (style.font) {
    return style.font;
  }
  var isFontDefined = style.fontFamily !== "";
  if (!isFontDefined) {
    return "";
  }
  var fontVariant = allowedVariants.includes(style.fontVariant) ? style.fontVariant : "normal";
  return "".concat(style.fontStyle, " ").concat(fontVariant, " ").concat(style.fontWeight, " ").concat(style.fontSize, " / ").concat(style.lineHeight, " ").concat(style.fontFamily);
}
exports.getFontShorthand = getFontShorthand;
var cachedCanvas;
/**
 * Measures text width given text and font CSS shorthand.
 *
 * @param {string} text Text to measure
 * @param {string} font Font to use when measuring the text
 */
function measureText(text, font) {
  var canvas = cachedCanvas || (cachedCanvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  // Context type not supported
  if (!context) {
    return null;
  }
  context.font = font;
  var width = context.measureText(text).width;
  return Math.ceil(width);
}
exports.measureText = measureText;
/**
 * Updates input element width to fit its content given input element
 * @param {HTMLInputElement} element
 */
function updateInputWidth(element) {
  if (typeof document === "undefined" || !element) {
    return null;
  }
  var font = getFontShorthand(element);
  var text = element.value || element.placeholder;
  var width = measureText(text, font);
  if (width === null) {
    return null;
  }
  element.style.width = "".concat(width, "px");
  return width;
}
exports.updateInputWidth = updateInputWidth;
exports.default = updateInputWidth;
export default exports;
export const __esModule = exports.__esModule;
const _updateInputWidth = exports.updateInputWidth,
  _measureText = exports.measureText,
  _getFontShorthand = exports.getFontShorthand;
export { _updateInputWidth as updateInputWidth, _measureText as measureText, _getFontShorthand as getFontShorthand };